<template>
  <div class="icc">
    <div class="content">
      <h1 class="icc__title">Клинический случай</h1>
    </div>
    <Breadcrumbs
      :pages="[
        {
          name: 'Клинический случай',
        },
      ]"
    />
    <div class="content">
      <div class="icc__block" v-if="!start && loading">
        <div class="icc__block-body icc__block-body_preloader">
          <div class="icc__block-preloader">
            <Preloader class="preloader" />
          </div>
        </div>
      </div>
      <div class="icc__block" v-if="!start && !loading">
        <div class="icc__block-body">
          <div class="icc__block-row">
            <div class="icc__block-text">
              <div class="icc__block-title icc__block-title_with-icon">
                <img
                  :src="
                    require('@/assets/img/interactive-clinical-case/step0_icon1.svg')
                  "
                />
                Женщина 53 года
              </div>
              <div class="icc__block-subtitle">
                Обратилась 05.07.2024г. к кардиологу в поликлинике по
                направлению участкового терапевта.
              </div>
              <div class="icc__block-description">
                В ходе интерактивного клинического разбора Вам будут
                представлены жалобы пациентки в виде видео материалов, данные из
                медицинской карты пациента*, комментарии эксперта. Вам
                необходимо верно установить диагноз, рекомендовать обследование
                и лечение.
              </div>
            </div>
            <div class="icc__block-video">
              <video
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/1.mp4"
                :poster="
                  require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                "
                controls
              />
            </div>
          </div>
          <div
            class="icc__block-button button button_pink-light"
            @click="startCase()"
          >
            Помочь пациентке
          </div>
        </div>
        <div class="icc__block-steps">
          <Steps :hideArrows="true" :stepsCount="18" :activeStep="1" />
        </div>
      </div>
      <ClinicalCase
        :key="iccKey"
        @refreshClinicalCase="refreshClinicalCase"
        @getUserProgress="getUserProgress"
        :fullData="fullData"
        @close="closeCase"
        v-show="start"
        v-if="!loading"
      />
      <div class="icc__tip">
        Примечание: *в интерактивном клиническом случае представлен
        гипотетический пациент, даты выбраны произвольно для возможности оценки
        состояния в динамике.
      </div>
      <div class="icc__sources">
        <div class="icc__sources-title">Источники</div>
        <input id="icc-sources" type="checkbox" />
        <ul class="icc__sources-list">
          <li class="icc__sources-list-item">
            Фибрилляция и трепетание предсердий. Клинические рекомендации 2020 /
            М.Г. Аракелян, Л.А. Бокерия, Е.Ю. Васильева [и др.] // Российский
            кардиологический журнал – 2021. – Т.26. – № 7. – С. 190-260.
            https://doi.org/10.15829/1560-4071-2021-4594
          </li>
          <li class="icc__sources-list-item">
            Приказ Министерства здравоохранения Российской Федерации от 28
            декабря 2012 г. № 1622н "Об утверждении стандарта специализированной
            медицинской помощи при фибрилляции и трепетании предсердий"
          </li>
          <li class="icc__sources-list-item">
            Барбараш О.Л., Карпов Ю.А., Панов А.В., Акчурин Р.С., и другие.
            Стабильная ишемическая болезнь сердца. Клинические рекомендации
            2024. Российский кардиологический журнал. 2024;29(9):6110.
            https://doi.org/10.15829/1560-4071-2024-6110.
          </li>
          <li class="icc__sources-list-item">
            Кобалава Ж.Д., Конради А.О., Недогода С.В., Шляхто Е.В., Арутюнов
            Г.П., и другие. Артериальная гипертензия у взрослых. Клинические
            рекомендации 2024. Российский кардиологический журнал.
            2024;29(9):6117. https://doi.org/10.15829/1560-4071-2024-6117.
          </li>
          <li class="icc__sources-list-item">
            Хроническая сердечная недостаточность. Клинические рекомендации
            2024. Одобрено Научно-практическим Советом Минздрава РФ.
            https://cr.minzdrav.gov.ru/recomend/156_2?ysclid=m2kkjmq81u504184541
          </li>
          <li class="icc__sources-list-item">
            Ожирение. Клинические рекомендации 2020 / Российская ассоциация
            эндокринологов, Общество бариатрических хирургов
          </li>
          <li class="icc__sources-list-item">
            Нарушения липидного обмена. Клинические рекомендации 2023 / М.В.
            Ежов, В.В. Кухарчук, И.В. Сергиенко [и др.] // Российский
            кардиологический журнал – 2023. – Т.28. – № 5. – С.5471.
          </li>
          <li class="icc__sources-list-item">
            Общая характеристика лекарственного препарата Беталок® ЗОК (таблетки
            с пролонгированным высвобождением, покрытые оболочкой, 25 мг, 50 мг,
            100 мг). Регистрационное удостоверение ЛП-№(002842)-(РГ-RU) от
            07.08.2024
            https://portal.eaeunion.org/sites/commonprocesses/ru-ru/Pages/CardView.aspx?documentId=63a97240fb44f154421a2cfc&codeId=P.MM.01
          </li>
          <li class="icc__sources-list-item">
            Рекомендации ESC 2020 по диагностике и лечению пациентов с
            фибрилляцией предсердий, разработанные совместно с Европейской
            ассоциацией кардиоторакальной хирургии (EACTS) / G. Hindricks, T.
            Potpara, N. Dagres, [et al.] // Российский кардиологический журнал.
            – 2021. – Т.26. – №9. – С. 234-329. https://doi.org/
            10.15829/1560-4071-2021-4701
          </li>
          <li class="icc__sources-list-item">
            Ежов М.В., Сергиенко И.В., Кухарчук В.В. Атеросклероз и
            дислипидемии. Клинические рекомендации по нарушениям липидного
            обмена 2023. Что нового? 2023;3(52):5–9. DOI: 10.34687/2219
            –8202.JAD.2023.03.0001
          </li>
          <li class="icc__sources-list-item">
            Hu M, Tomlinson B. Evaluation of the pharmacokinetics and drug
            interactions of the two recently developed statins, rosuvastatin and
            pitavastatin. Expert Opin Drug Metab Toxicol. 2014;10(1):51-65. doi:
            10.1517/17425255.2014.851667.
          </li>
          <li class="icc__sources-list-item">
            Jones PH, Davidson MH, Stein EA, Bays HE, McKenney JM, Miller E, et
            al.; STELLAR Study Group. Comparison of the efficacy and safety of
            rosuvastatin versus atorvastatin, simvastatin, and pravastatin
            across doses (STELLAR* Trial). Am J Cardiol. 2003;92(2):152-160.
            doi: 10.1016/s0002-9149(03)00530-7
          </li>
          <li class="icc__sources-list-item">
            Laks T, Keba E, Leiner M, Merilind E, Petersen M, Reinmets S, Väli
            S, Sööt T, Otter K. Achieving lipid goals with rosuvastatin compared
            with simvastatin in high risk patients in real clinical practice: a
            randomized, open-label, parallel-group, multi-center study: the
            DISCOVERY-Beta study. Vasc Health Risk Manag. 2008;4(6):1407-16.
            doi: 10.2147/vhrm.s4151. PMID: 19337553; PMCID: PMC2663459.
          </li>
          <li class="icc__sources-list-item">
            Ridker PM, Danielson E, Fonseca FA, Genest J, Gotto AM Jr, Kastelein
            JJ et al.; JUPITER Trial Study Group. Reduction in C-reactive
            protein and LDL cholesterol and cardiovascular event rates after
            initiation of rosuvastatin: a prospective study of the JUPITER
            trial. Lancet. 2009;373(9670):1175-1182. doi:
            10.1016/S0140-6736(09)60447-5.
          </li>
          <li class="icc__sources-list-item">
            Lind L, Peters SA, den Ruijter HM, Palmer MK, Grobbee DE, Crouse JR
            3rd, O'Leary DH, Evans GW, RaichlenJS, Bots ML; METEOR Study Group.
            Effect of rosuvastatin on the echolucency of the common carotid
            intima-media in low-risk individuals: the METEOR trial. J Am Soc
            Echocardiogr. 2012;25(10):1120–1127.e1. doi:
            10.1016/j.echo.2012.07.004.PMID: 22884641.
          </li>
          <li class="icc__sources-list-item">
            Nicholls SJ, Ballantyne CM, Barter PJ, Chapman MJ, Erbel RM, Libby
            P, Raichlen JS, Uno K, Borgman M, WolskiK, Nissen SE. Effect of two
            intensive statin regimens on progression of coronary disease. N Engl
            J Med. 2011;365(22):2078–87. doi: 10.1056/NEJMoa1110874. PMID:
            22085316
          </li>
          <li class="icc__sources-list-item">
            17. Инструкция по медицинскому применению препарата Крестор,
            Рег.удос. ЛП (003184)-(РГ-RU) от 14.09.2023, ЛП-N (002827)-(РГ-RU)
            от 21.07.2023
          </li>
          <li class="icc__sources-list-item">
            Nicholls SJ, Brandrup-Wognsen G, Palmer M, Barter PJ. Meta-analysis
            of comparative efficacy of increasing dose of Atorvastatin versus
            Rosuvastatin versus Simvastatin on lowering levels of atherogenic
            lipids (from VOYAGER). Am J Cardiol. 2010 Jan 1;105(1):69-76. doi:
            10.1016/j.amjcard.2009.08.651. PMID: 20102893.
          </li>
          <li class="icc__sources-list-item">
            Palmer MK, Nicholls SJ, Lundman P, Barter PJ, Karlson BW.
            Achievement of LDL-C goals depends on baseline LDL-C and choice and
            dose of statin: an analysis from the VOYAGER database. Eur J Prev
            Cardiol. 2013 Dec;20(6):1080-7. doi: 10.1177/2047487313489875. Epub
            2013 May 3. PMID: 23644489.
          </li>
          <li class="icc__sources-list-item">
            Karlson BW, Nicholls SJ, Lundman P, Barter PJ, Palmer MK. Modeling
            Statin-Induced Reductions of Cardiovascular Events in Primary
            Prevention: A VOYAGER Meta-Analysis. Cardiology. 2018;140(1):30–4.
            DOI: 10.1159/000488311
          </li>
          <li class="icc__sources-list-item">
            Karlson BW, Barter PJ, Palmer MK, Lundman P, Nicholls SJ. Comparison
            of the effects of different statins and doses on lipid levels in
            patients with diabetes: Results from VOYAGER. Nutri- tion,
            Metabolism and Cardiovascular Diseases. 2012;22(9):697– 703. DOI:
            10.1016/j.numecd.2012.03.003
          </li>
          <li class="icc__sources-list-item">
            Karlson BW, Palmer MK, Nicholls SJ, Lundman P, Barter PJ. Doses of
            rosuvastatin, atorvastatin and simvastatin that induce equal
            reductions in LDL-C and non-HDL-C: Results from the VOYAGER
            meta-analysis. Eur J Prev Cardiol. 2016 May;23(7):744-7. doi:
            10.1177/2047487315598710. Epub 2015 Aug 5. PMID: 26246463.
          </li>
          <li class="icc__sources-list-item">
            Peikert A, Martinez FA, Vaduganathan M, et al. Efficacy and Safety
            of Dapagliflozin in Heart Failure With Mildly Reduced or Preserved
            Ejection Fraction According to Age: The DELIVER Trial. Circ Heart
            Fail. 2022;15(10):e010080.
          </li>
          <li class="icc__sources-list-item">
            Батюшин М.М. Применение ингибиторов натрий-глюкозного котранспортера
            2 типа при хронической сердечной недостаточности и хронической
            болезни почек. Роль эмпаглифлозина. Российский кардиологический
            журнал. 2021;26(1S):4349.
          </li>
          <li class="icc__sources-list-item">
            Кузьменко А.А., Соколова А.А., Напалков Д.А. Хроническая сердечная
            недостаточность с сохраненной фракцией выброса: оптимальная
            медикаментозная терапия. Обзор литературы. Сеченовский вестник.
            2022; 13(3): 4–13.
          </li>
        </ul>
        <label
          for="icc-sources"
          class="icc__sources-button button button_empty-pink"
        ></label>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import ClinicalCase from "@/components/interactiveClinicalCase/ClinicalCase.vue";
import Preloader from "@/components/Preloader.vue";
import { bus } from "@/main";
import { mapGetters } from 'vuex';
export default {
  name: "InteractiveClinicalCase",
  components: {
    Breadcrumbs,
    Steps,
    ClinicalCase,
    Preloader,
  },
  data: () => ({
    start: false,
    fullData: null,
    loading: true,
    iccKey: 0,
  }),
  computed: {
    ...mapGetters(['user']),
    params() {
      let params = new URLSearchParams
      if (this.$route.query.access) {
        params.append('access', this.$route.query.access)
      }
      return params
    }
  },
  methods: {
    startCase() {
      this.start = true;
      if (window.innerWidth < 1220) {
        bus.$emit("scrollLock", true);
      }
      if (this.fullData && !this.fullData[1].is_viewed) {
        this.$axios({
          url: `/api/clinical-case/user-progress`,
          params: this.params,
          method: "POST",
          data: {
            step: this.fullData[1].slug,
          },
        })
          .then((userProgress) => {})
          .catch((error) => {});
      }
    },
    closeCase() {
      window.scrollTop = 0;
      this.start = false;
      if (window.innerWidth < 1220) {
        bus.$emit("scrollLock", false);
      }
    },
    refreshClinicalCase() {
      this.$axios({
        url: `/api/clinical-case/steps/finish/`,
        params: this.params,
        method: "POST",
      })
        .then((res) => {
          this.getUserProgress().then(() => {
            this.closeCase();
            this.iccKey++;
          });
        })
        .catch((error) => {});
    },
    async getUserProgress() {
      return await this.$axios({
        url: `/api/clinical-case/steps/`,
        params: this.params,
        method: "GET",
      })
        .then((res) => {
          this.fullData = res.data;
          this.loading = false;

          if (!res.data[0].is_viewed) {
            this.$axios({
              url: `/api/clinical-case/user-progress`,
              params: this.params,
              method: "POST",
              data: {
                step: res.data[0].slug,
              },
            })
              .then((userProgress) => {})
              .catch((error) => {});
          }
          if (res.data[1].is_viewed) {
            this.startCase();
          }
        })
        .catch((error) => {});
    },
  },
  mounted() {
    this.getUserProgress();
  },
};
</script>

<style lang="scss" scoped>
.icc {
  &__title {
    margin-top: 32px;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #000000;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__block {
    margin-top: 32px;
    margin-bottom: 20px;
    &-body {
      padding: 32px 24px;
      border: 1px solid #d2d2d2;
      border-radius: 32px 32px 0 0;
      background-color: #f8f8f8;
      @media screen and (max-width: 1220px) {
        border-radius: 32px;
      }

      &_preloader {
        border-radius: 32px;
        height: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1220px) {
          height: auto;
        }
      }
    }

    &-preloader {
      width: 100px;
      height: 100px;
      color: #830051;
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 32px;

      @media screen and (max-width: 1220px) {
        flex-direction: column;
      }
    }

    &-title {
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      color: #830051;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
        font-size: 21px;
        line-height: 26px;
      }

      & img {
        margin-right: 16px;
      }
    }

    &-subtitle {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 24px;
      }
    }

    &-description {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-video {
      width: 55%;
      flex-shrink: 0;

      @media screen and (max-width: 1220px) {
        width: 100%;
      }

      & video {
        width: 100%;
      }
    }

    &-button {
      margin: 145px auto 0;

      @media screen and (max-width: 1220px) {
        margin: 24px auto 0;
      }
    }

    &-steps {
      padding: 20px 24px;
      border: 1px solid #d2d2d2;
      border-radius: 0 0 32px 32px;
      background-color: #f8d9e9;

      @media screen and (max-width: 1220px) {
        display: none;
      }
    }
  }

  &__tip {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__sources {
    margin-top: 100px;
    @media screen and (max-width: 767px) {
      margin-top: 70px;
    }
    &-title {
      margin-bottom: 50px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      text-align: center;
      color: #1f1f1f;

      @media screen and (max-width: 767px) {
        margin-bottom: 32px;
        font-size: 23px;
        line-height: 29px;
      }
    }

    &-list {
      padding-left: 25px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #3c4242;
      list-style-type: decimal;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }

      &-item {
        margin-bottom: 8px;
        &:nth-child(n + 6) {
          display: none;
        }
      }
    }

    & input {
      display: none;

      &:checked {
        & ~ .icc__sources-list {
          & .icc__sources-list-item {
            &:nth-child(n + 6) {
              display: list-item;
            }
          }
        }

        & ~ .icc__sources-button {
          &::before {
            content: "Скрыть";
          }
        }
      }
    }

    &-button {
      margin: 40px auto 0;
      &::before {
        content: "Все источники";
      }
    }
  }
}
</style>

<style lang="scss">
.icc {
  & .preloader {
    width: 100%;
    height: 100%;
    & div {
      border-width: 6px;
      border-color: #830051 transparent transparent transparent;
    }
  }
}
</style>